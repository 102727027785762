<template>
    <div class="animated fadeIn">
        <b-card>
            <div slot="header">
                <strong style="margin-left: 5px">Are you done with analytics automation?</strong>
            </div>
            <div>
                If you're sure you'd like to deactivate your billing account, finish below.
                <b-card class="deactivate-card">
                    <ul>
                        <li>Your workflows will be no longer processed.</li>
                        <li>
                            Come back anytime and activate you billing account again. Your resources and settings will
                            be waiting for you.
                        </li>
                    </ul>

                    <div>
                        <Feedback v-if="witError" :state="witError.state" :invalid="witError.message"></Feedback>
                    </div>

                    <div>
                        <b-button @click="deactivate" variant="primary">Finish deactivation</b-button>
                    </div>
                </b-card>
            </div>
        </b-card>
        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'

export default {
    components: {
        Feedback,
        Loading,
    },
    data() {
        return {
            witError: {
                state: true,
                message: '',
            },
        }
    },
    methods: {
        async deactivate() {
            this.witError = {
                state: true,
                message: '',
            }

            try {
                this.$store.commit('loading/PROCESSING', `Deactivating your billing account...`)

                const response = await this.axios.post(
                    `${process.env.VUE_APP_NODE_API_HOST}/billingAccount/deactivate`,
                    {billingAccountId: this.$route.params.id}
                )
                await this.$store.dispatch('billing/setAccount', this.$route.params.id)

                this.$store.commit('loading/PROCESSED')
                this.$projectRouter.push(`/billing?t=subscription&accountDeactivated=true`)
            } catch (exception) {
                this.witError.state = false
                this.witError.message = exception.response.data.data
                this.$store.commit('loading/PROCESSED')
                this.$errorHandler.report(exception, 'Could not deactivate billing account')
            }
        },
    },
}
</script>

<style lang="scss">
.deactivate-card {
    background-color: #f0f3f5;
    border-radius: 5px;
    margin: 15px 5px;
}
</style>
